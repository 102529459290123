import { CalendarDate } from "@sp-crm/core";
import { produce } from "immer";
import { Action } from "redux";
import { Actions } from "../actions";

export interface ReportParameterState {
    inboundReferral: {
        startDate: CalendarDate;
        endDate: CalendarDate;
    };
    clientPlacement: {
        startDate: CalendarDate;
        endDate: CalendarDate;
        showPerUser: boolean;
    };
    summary: {
        startDate: CalendarDate;
        endDate: CalendarDate;
    };
}

const daysAgo = (days: number) => {
    const d = new Date();
    d.setTime(d.getTime() - 1000 * 60 * 60 * 24 * days);
    return d;
};

export function reportParametersReducer(
    originalState: ReportParameterState,
    action: Action,
): ReportParameterState {
    if (!originalState) {
        const past = CalendarDate.fromDate(daysAgo(365));
        const now = CalendarDate.fromDate(new Date());
        originalState = {
            inboundReferral: { startDate: past, endDate: now },
            clientPlacement: { startDate: past, endDate: now, showPerUser: true },
            summary: {
                startDate: CalendarDate.fromDate(daysAgo(90)).monthBegin(),
                endDate: CalendarDate.fromDate(new Date()).monthBegin().nextMonth(),
            },
        };
    }

    return produce(originalState, state => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const value = (action as any).value;
        switch (action.type) {
            case Actions[Actions.REPORT_PARAMETER_INBOUND_REFERRAL_START_DATE_UPDATE]:
                state.inboundReferral.startDate = value;
                break;
            case Actions[Actions.REPORT_PARAMETER_INBOUND_REFERRAL_END_DATE_UPDATE]:
                state.inboundReferral.endDate = value;
                break;
            case Actions[Actions.REPORT_PARAMETER_CLIENT_PLACEMENT_START_DATE_UPDATE]:
                state.clientPlacement.startDate = value;
                break;
            case Actions[Actions.REPORT_PARAMETER_CLIENT_PLACEMENT_END_DATE_UPDATE]:
                state.clientPlacement.endDate = value;
                break;
            case Actions[Actions.REPORT_PARAMETER_CLIENT_PLACEMENT_SHOW_PER_USER_UPDATE]:
                state.clientPlacement.showPerUser = value;
                break;
            case Actions[Actions.REPORT_PARAMETER_SUMMARY_START_DATE_UPDATE]:
                state.summary.startDate = value;
                break;
            case Actions[Actions.REPORT_PARAMETER_SUMMARY_END_DATE_UPDATE]:
                state.summary.endDate = value;
                break;
        }
    });
}
