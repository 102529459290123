import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
    CoordinatesInput,
    SearchGeoParams,
    SearchUserProfileRequest,
} from "generated/graphql";

export interface AgentDirectoryFilter {
    search: string;
    geoParams: SearchGeoParams | null;
}

interface PageSizeChange {
    pageSize: number;
}

interface PageChange {
    page: number;
}

interface SetExplicitMapBounds {
    northWest: CoordinatesInput;
    southEast: CoordinatesInput;
}

const initialState = {
    searchFilter: {
        search: "",
        geoParams: null,
    } as AgentDirectoryFilter,
    searchRequest: {
        query: "",
        geoParams: null,
        page: 0,
        perPage: 25,
        sort: "name",
        sortDirection: "ASC",
    } as SearchUserProfileRequest,
};
export const agentDirectorySlice = createSlice({
    name: "agentDirectory",
    initialState: initialState,
    reducers: {
        updateSearch: (state, action: PayloadAction<AgentDirectoryFilter>) => {
            state.searchFilter = action.payload;
            state.searchRequest.query = action.payload.search;
            state.searchRequest.geoParams = action.payload.geoParams;
        },
        changePageSize: (state, action: PayloadAction<PageSizeChange>) => {
            state.searchRequest.perPage = action.payload.pageSize;
        },
        changePage: (state, action: PayloadAction<PageChange>) => {
            state.searchRequest.page = action.payload.page;
        },
        setExplicitMapBounds: (state, action: PayloadAction<SetExplicitMapBounds>) => {
            setBounds(state.searchFilter, action.payload);
            setBounds(state.searchRequest, action.payload);
            state.searchRequest.page = 0;
        },
        resetMapBounds: state => {
            clearBounds(state.searchFilter);
            clearBounds(state.searchRequest);
            state.searchRequest.page = 0;
        },
    },
});

const setBounds = (
    parent: { geoParams?: SearchGeoParams | null },
    bounds: SetExplicitMapBounds,
) => {
    if (!parent.geoParams) {
        parent.geoParams = {};
    }

    parent.geoParams.bounds = {
        northWest: bounds.northWest,
        southEast: bounds.southEast,
    };
};

const clearBounds = (parent: { geoParams?: SearchGeoParams | null }) => {
    if (parent.geoParams) {
        delete parent.geoParams.bounds;
        if (!parent.geoParams.matchType) {
            parent.geoParams = null;
        }
    }
};
