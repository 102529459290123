import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EVERYONE_SENTINEL, RegionId } from "@sp-crm/core";
import { SelectableUser } from "components/shared/user-select";
import {
    AdvancedSearchCondition,
    CoordinatesInput,
    ReferenceBusinessSearchRequest,
    ReferenceCommunitySearchRequest,
    ReferenceContactSearchRequest,
    ReferenceOrganizationSearchRequest,
    SearchGeoParams,
} from "generated/graphql";

export interface ReferenceFilter {
    search: string;
    orgTypes: string[];
    contactCondition: AdvancedSearchCondition | null;
    businessCondition: AdvancedSearchCondition | null;
    communityCondition: AdvancedSearchCondition | null;
    assignedUserId: SelectableUser;
    geoParams: SearchGeoParams | null;
}

export type ReferenceDashboardAspect =
    | "contact"
    | "business"
    | "community"
    | "organization";

interface PageSizeChange {
    aspect: ReferenceDashboardAspect;
    pageSize: number;
}

interface PageChange {
    aspect: ReferenceDashboardAspect;
    page: number;
}

interface Sort {
    aspect: ReferenceDashboardAspect;
    sort: string;
}

interface SortWithDirection extends Sort {
    direction: "ASC" | "DESC";
}

interface SetExplicitMapBounds {
    northWest: CoordinatesInput;
    southEast: CoordinatesInput;
}

const initialState = {
    persistMode: "off",
    regionId: null as RegionId,
    searchFilter: {
        search: "",
        orgTypes: [],
        contactCondition: null,
        businessCondition: null,
        communityCondition: null,
        assignedUserId: EVERYONE_SENTINEL,
        geoParams: null,
    } as ReferenceFilter,
    contactSearchRequest: {
        page: 0,
        perPage: 25,
        search: "",
        sort: "numberOfReferrals",
        sortDirection: "DESC",
        assignedUserId: null,
    } as Omit<ReferenceContactSearchRequest, "regionId">,
    businessSearchRequest: {
        page: 0,
        perPage: 25,
        search: "",
        sort: "numberOfReferrals",
        sortDirection: "DESC",
        assignedUserId: null,
    } as Omit<ReferenceBusinessSearchRequest, "regionId">,
    communitySearchRequest: {
        page: 0,
        perPage: 25,
        search: "",
        sort: "numberOfReferrals",
        sortDirection: "DESC",
    } as Omit<ReferenceCommunitySearchRequest, "regionId">,
    organizationSearchRequest: {
        page: 0,
        perPage: 25,
        search: "",
        sort: "numberOfReferrals",
        sortDirection: "DESC",
    } as Omit<ReferenceOrganizationSearchRequest, "regionId">,
};
export const referenceDashboardSlice = createSlice({
    name: "referenceDashboard",
    initialState: initialState,
    reducers: {
        updateSearch: (state, action: PayloadAction<ReferenceFilter>) => {
            state.searchFilter = action.payload;
            const trimmedSearch = (action.payload.search ?? "").trim();
            state.contactSearchRequest.search = trimmedSearch;
            state.businessSearchRequest.search = trimmedSearch;
            state.communitySearchRequest.search = trimmedSearch;
            state.organizationSearchRequest.search = trimmedSearch;
            state.searchFilter.assignedUserId = action.payload.assignedUserId;
            if (
                action.payload.assignedUserId &&
                action.payload.assignedUserId !== EVERYONE_SENTINEL
            ) {
                state.contactSearchRequest.assignedUserId = action.payload.assignedUserId;
                state.businessSearchRequest.assignedUserId =
                    action.payload.assignedUserId;
                state.organizationSearchRequest.assignedUserId =
                    action.payload.assignedUserId;
            } else {
                state.contactSearchRequest.assignedUserId = null;
                state.businessSearchRequest.assignedUserId = null;
                state.organizationSearchRequest.assignedUserId = null;
            }
            state.contactSearchRequest.organizationTypes = action.payload.orgTypes;
            state.businessSearchRequest.organizationTypes = action.payload.orgTypes;
            state.organizationSearchRequest.organizationTypes = action.payload.orgTypes;
            state.contactSearchRequest.contactCondition = action.payload.contactCondition;
            state.contactSearchRequest.businessCondition =
                action.payload.businessCondition;
            state.businessSearchRequest.businessCondition =
                action.payload.businessCondition;
            state.organizationSearchRequest.businessCondition =
                action.payload.businessCondition;
            state.contactSearchRequest.communityCondition =
                action.payload.communityCondition;
            state.communitySearchRequest.communityCondition =
                action.payload.communityCondition;
            state.organizationSearchRequest.communityCondition =
                action.payload.communityCondition;
            state.contactSearchRequest.page = 0;
            state.businessSearchRequest.page = 0;
            state.communitySearchRequest.page = 0;
            state.organizationSearchRequest.page = 0;
            state.contactSearchRequest.perPage = 25;
            state.businessSearchRequest.perPage = 25;
            state.communitySearchRequest.perPage = 25;
            state.organizationSearchRequest.perPage = 25;
            state.contactSearchRequest.geoParams = action.payload.geoParams;
            state.businessSearchRequest.geoParams = action.payload.geoParams;
            state.communitySearchRequest.geoParams = action.payload.geoParams;
            state.organizationSearchRequest.geoParams = action.payload.geoParams;
        },
        changePageSize: (state, action: PayloadAction<PageSizeChange>) => {
            const { aspect, pageSize } = action.payload;
            const request = state[`${aspect}SearchRequest`];
            if (request) {
                request.perPage = pageSize;
            }
        },
        changePage: (state, action: PayloadAction<PageChange>) => {
            const { aspect, page } = action.payload;
            const request = state[`${aspect}SearchRequest`];
            if (request) {
                request.page = page;
            }
        },
        changeSort: (state, action: PayloadAction<Sort>) => {
            const { aspect, sort: column } = action.payload;
            const request = state[`${aspect}SearchRequest`];
            if (request) {
                if (request.sort === column) {
                    request.sortDirection =
                        request.sortDirection === "ASC" ? "DESC" : "ASC";
                } else {
                    request.sort = column;
                    request.sortDirection = "ASC";
                }
                request.page = 0;
            }
        },
        changeSortWithDirection: (state, action: PayloadAction<SortWithDirection>) => {
            const { aspect, sort, direction } = action.payload;
            const request = state[`${aspect}SearchRequest`];
            if (request) {
                request.sort = sort;
                request.sortDirection = direction;
                request.page = 0;
            }
        },
        setExplicitMapBounds: (state, action: PayloadAction<SetExplicitMapBounds>) => {
            setBounds(state.searchFilter, action.payload);
            setBounds(state.contactSearchRequest, action.payload);
            setBounds(state.businessSearchRequest, action.payload);
            setBounds(state.communitySearchRequest, action.payload);
            setBounds(state.organizationSearchRequest, action.payload);
            state.contactSearchRequest.page = 0;
        },
        resetMapBounds: state => {
            clearBounds(state.searchFilter);
            clearBounds(state.contactSearchRequest);
            clearBounds(state.businessSearchRequest);
            clearBounds(state.communitySearchRequest);
            clearBounds(state.organizationSearchRequest);
        },
        updatePersistence: (state, action: PayloadAction<"on" | "off">) => {
            state.persistMode = action.payload;
        },
        updateRegion: (state, action: PayloadAction<RegionId>) => {
            if (action.payload === state.regionId) {
                return state;
            }
            if (!state.regionId) {
                state.regionId = action.payload;
                return state;
            }

            return {
                ...initialState,
                regionId: action.payload,
                persistMode: state.persistMode,
            };
        },
    },
});

const setBounds = (
    parent: { geoParams?: SearchGeoParams | null },
    bounds: SetExplicitMapBounds,
) => {
    if (!parent.geoParams) {
        parent.geoParams = {};
    }

    parent.geoParams.bounds = {
        northWest: bounds.northWest,
        southEast: bounds.southEast,
    };
};

const clearBounds = (parent: { geoParams?: SearchGeoParams | null }) => {
    if (parent.geoParams) {
        delete parent.geoParams.bounds;
        if (!parent.geoParams.matchType) {
            parent.geoParams = null;
        }
    }
};
