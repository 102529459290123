import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SignatureRequestFilter, SignatureRequestState } from "generated/graphql";

interface SignwiseDashboardState {
    filter: Omit<SignatureRequestFilter, "regionId">;
}
const initialState: SignwiseDashboardState = {
    filter: {},
};

export const signwiseDashboardSlice = createSlice({
    name: "signwiseDashboard",
    initialState,
    reducers: {
        updateSearchText: (state, action: PayloadAction<string>) => {
            state.filter.search = action.payload;
        },
        updateIncludeStates: (state, action: PayloadAction<SignatureRequestState[]>) => {
            state.filter.includeStates = action.payload;
        },
    },
});
