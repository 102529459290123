import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { clientSearchReducer } from "store/reducers/client-search";
import { clientsReducer } from "store/reducers/clients";
import { communitiesReducer } from "store/reducers/communities";
import { communitySearchReducer } from "store/reducers/community-search";
import { emailReducer } from "store/reducers/email";
import { eulaReducer } from "store/reducers/eula";
import { globalNotificationsReducer } from "store/reducers/failures";
import { filesReducer } from "store/reducers/files";
import { guestInformationReducer } from "store/reducers/guest-information";
import { loginReducer } from "store/reducers/login";
import { notificationReducer } from "store/reducers/notification";
import { permissionsReducer } from "store/reducers/permissions";
import { referralsReducer } from "store/reducers/referrals";
import { regionReducer } from "store/reducers/region";
import { reportParametersReducer } from "store/reducers/report-parameters-reducer";
import { resetPasswordReducer } from "store/reducers/reset-password";
import { responsiveReducer } from "store/reducers/responsive";
import { selectedContactReducer } from "store/reducers/selected-contact";
import { setPasswordReducer } from "store/reducers/set-password";
import { statusReducer } from "store/reducers/status";
import { templateReducer } from "store/reducers/templates";
import { tenantReducer } from "store/reducers/tenant";
import { timeReducer } from "store/reducers/time";
import { singleUserReducer } from "store/reducers/user";
import { usersReducer } from "store/reducers/users";
import { getInitialRegionState } from "util/sync-state";
import { settingsReducer } from "./reducers/settings";
import { agentDirectorySlice } from "./slices/agent-directory";
import { customReportsSlice } from "./slices/custom-reports";
import { filesDashboardSlice } from "./slices/files-dashboard";
import { referenceDashboardSlice } from "./slices/reference-dashboard";
import { signwiseDashboardSlice } from "./slices/signwise-dashboard";
import { taskDashboardSlice } from "./slices/tasks-dashboard";

interface OptionalStoredSlice {
    persistMode: "on" | "off";
}

const optionalStoredSliceTransform = (reducerKey: string) => {
    return createTransform(
        (inboundState, key) => {
            return inboundState;
        },
        (outboundState: OptionalStoredSlice, key) => {
            if (outboundState.persistMode === "on") {
                return outboundState;
            }

            return undefined;
        },
        {
            whitelist: [reducerKey],
        },
    );
};

const combinedReducer = combineReducers({
    clients: clientsReducer,
    clientSearch: clientSearchReducer,
    communities: communitiesReducer,
    communitySearch: communitySearchReducer,
    settings: settingsReducer,
    status: statusReducer,
    userState: singleUserReducer,
    users: usersReducer,
    eula: eulaReducer,
    login: loginReducer,
    time: timeReducer,
    selectedContacts: selectedContactReducer,
    notifications: notificationReducer,
    tenant: tenantReducer,
    responsive: responsiveReducer,
    email: emailReducer,
    resetPassword: resetPasswordReducer,
    setPassword: setPasswordReducer,
    templates: templateReducer,
    globalNotifications: globalNotificationsReducer,
    files: filesReducer,
    region: regionReducer,
    permissions: permissionsReducer,
    referrals: referralsReducer,
    reportParameters: reportParametersReducer,
    guestInformation: guestInformationReducer,
    referenceDashboard: referenceDashboardSlice.reducer,
    taskDashboard: persistReducer(
        {
            key: "root",
            version: 1,
            storage: storage,
            whitelist: ["sectionVisibility"],
        },
        taskDashboardSlice.reducer,
    ),
    filesDashboard: filesDashboardSlice.reducer,
    customReports: customReportsSlice.reducer,
    signwiseDashboard: signwiseDashboardSlice.reducer,
    agentDirectory: agentDirectorySlice.reducer,
});

const persistedCombinedReducer: typeof combinedReducer = persistReducer(
    {
        key: "appState",
        version: 1,
        storage: storage,
        whitelist: ["referenceDashboard"],
        transforms: [optionalStoredSliceTransform("referenceDashboard")],
    },
    combinedReducer,
);

export const store = configureStore({
    reducer: persistedCombinedReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    preloadedState: {
        region: getInitialRegionState(),
    },
});

export const persistor = persistStore(store);

export type ApplicationState = ReturnType<typeof store.getState>;
